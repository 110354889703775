<template>
  <div>
    <h1 style="font-size: 30px">{{ this.$t('app.news') }}</h1>
    <el-collapse v-for= "item in newsList" :key="item.id" v-model="activeName" accordion>
      <el-collapse-item :title="item.title" Consistency :name="item.id">
        <div style="text-align: left; background-color: #f7f7f7; padding: 1rem">
          <p style="text-align: center;font-size: 20px">{{ item.title }}</p>
          <span v-html="item.content" />
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {getIcAnnouncesList} from "@/api/rus/api";
import {mapGetters} from "vuex";

export default {
  name: "NewsAndInformation",
  data(){
    return{
      activeName: '1',
      newsList: [],
    }
  },
  computed: {
    ...mapGetters([
      "companyCode",
    ]),
  },
  created() {
    this.getList()
  },
  methods:{
    getList(){
      getIcAnnouncesList({'type':11,'companyCode':'RUS'}).then(res=>{
        this.newsList = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .el-collapse-item__header {
    margin-bottom: 10px;
  }
  ::v-deep .el-collapse-item__header {
    font-weight: 700;
    color: black;
    background-color: #f7f7f7;
    padding-left: 2rem;
  }

</style>
